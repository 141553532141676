import { createRouter, createWebHistory } from "vue-router";
import blog from "./modules/blog";
import shop from "./modules/shop";
import product from "./modules/product";
// import auth from "./modules/auth";
import { routes as mystorely } from "@ionsoft-digital/mystorely-portal";

const routes = [
    // ...auth,
    ...shop,
    ...blog,
    ...product,
    ...mystorely,
    {
        path: "/",
        name: "home",
        component: () => import("~/views/Index.vue"),
    },
    {
        path: "/contact-us",
        name: "contact-us",
        component: () => import("~/views/ContactUs.vue"),
    },
    {
        path: "/about-us",
        name: "about-us",
        component: () => import("~/views/AboutUs.vue"),
    },
    {
        path: "/landing-page",
        name: "landing-page",
        component: () => import("~/views/LandingPage.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("~/views/NotFound.vue"),
        meta: {
            layout: "empty",
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

// Navigation guard for all pages except for home page and login
router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
