import Repository from "~/repositories/Repository.js";
import { transformCartAndWishlist, transformCartToBackendRequest } from "../../helpers/shop";
import { toast } from "vue3-toastify";
import { localStorageKey } from "~/config/AppConfig";

const state = () => ({
    total: 0,
    cartItems: [],
    dialogAddCart: false,
    productOverview: null,
    aboutCart: null,
    dialogQuickview: false,
    dialogQuickviewLoading: false,
    dialogAddCart2: false,
    coupon: JSON.parse(localStorage.getItem(localStorageKey.coupon)) || null,
    countries: [],
    states: [],
});

const mutations = {
    SET_COUPON_CODE(state, payload) {
        state.coupon = payload;
        localStorage.setItem(localStorageKey.coupon, JSON.stringify(state.coupon));
    },
    SET_CART(state, payload) {
        state.cartItems = payload;
    },
    SET_COUNTRIES(state, payload) {
        state.countries = payload;
    },
    setDialogQuickviewLoading(state, payload) {
        state.dialogQuickviewLoading = payload;
    },
    setDialogAddCart(state, payload) {
        state.dialogAddCart = payload;
    },
    addItem(state, { product, id, qty, variant_id }) {
        product.image = product.image ? product.image : product.images[0].image;
        let existItem = state.cartItems.find((item) => item.id === id);
        if (existItem) {
            existItem.qty += qty;
        } else {
            state.cartItems.push({
                product,
                qty,
                variant_id,
                id,
            });
        }
    },
    removeItem: (state, id) => {
        const cartItems = state.cartItems;
        state.cartItems = cartItems.filter((el) => {
            return el.id != id;
        });
    },
    changeCartItemQuantity(state, payload) {
        const cartItems = state.cartItems;
        if (payload.qty == 0) {
            state.cartItems = cartItems.filter((el) => {
                return el.id != payload.id;
            });
        } else {
            const item = state.cartItems.find((item) => item.id === payload.id);
            item.qty = payload.qty;
        }
    },
    clearCart: (state) => {
        state.cartItems = [];
    },
    setProductSale(state, payload) {
        state.aboutCart = payload;
    },
    setTotal(state, payload) {
        state.total = payload;
    },
    setDialogQuickview(state, payload) {
        state.dialogQuickview = payload;
    },
    setProductOverview(state, payload) {
        state.productOverview = payload;
    },
};

const actions = {
    getShipState: async function ({ commit }, country_cd) {
        try {
            const { data } = await Repository.post("/setting/state/get", {
                country_cd,
            });
            return data.data;
        } catch (error) {
            console.log(error);
        }
    },
    getCountries: async function ({ commit }) {
        try {
            const { data } = await Repository.post("/setting/country/all");
            commit("SET_COUNTRIES", data.data);
            return data.data;
        } catch (error) {
            console.log(error);
        }
    },
    getCart: async function ({ commit }) {
        const { data: cartResult } = await Repository.post(
            "/cart/get",
            {
                currency_cd: "MYR",
            },
            { withCredentials: true }
        );
        const results = transformCartAndWishlist(cartResult.data.products);
        commit("SET_CART", results);
    },
    async addProductToCart({ commit, state }, payload) {
        commit("addItem", payload);
        const carts = transformCartToBackendRequest(state.cartItems);
        const { data } = await Repository.post(
            "/cart/update",
            { products: carts },
            { withCredentials: true }
        );
        if (data.status_code != 200) {
            commit("removeItem", payload.id);
            return toast.error(data.message[0]);
        }
        commit("setDialogAddCart", true);
        commit("setProductOverview", payload.product);
    },

    removeProductCart({ commit, state }, payload) {
        commit("removeItem", payload);
    },

    changeQuantityProductCart({ commit, state }, payload) {
        commit("changeCartItemQuantity", payload);
    },
    async updateCart({ state }, payload = {}) {
        const { showToast = true } = payload;
        try {
            const carts = transformCartToBackendRequest(state.cartItems);
            const { data } = await Repository.post(
                "/cart/update",
                { products: carts },
                { withCredentials: true }
            );
            if (data.status_code != 200) {
                return toast.error(data.message[0]);
            }
            if (showToast) {
                return toast.success(data.description);
            }
        } catch (error) {}
    },
    clearCart({ commit, state }) {
        commit("clearCart");
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
