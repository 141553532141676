<template>
    <header :class="['ps-header ps-header--1 ps-header--mobile', { 'ps-header--sticky': sticky }]">
        <notification />
        <div class="ps-header__middle">
            <div class="container">
                <div class="ps-logo">
                    <RouterLink to="/">
                        <img src="/img/mobile-logo.jpg" alt />
                    </RouterLink>
                </div>
                <div class="ps-header__right">
                    <ul class="ps-header__icons">
                        <li>
                            <a
                                class="ps-header__item open-search"
                                href="#"
                                @click.prevent="handleOpenDrawer('search')"
                            >
                                <i class="fas fa-search"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState } from "vuex";
import Notification from "~/components/shared/headers/modules/Notification.vue";

export default {
    components: {
        Notification,
    },
    data() {
        return {
            sticky: false,
        };
    },
    computed: {
        ...mapState({
            appDrawer: (state) => state.app.appDrawer,
        }),
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            let scroll = window.scrollY;
            if (scroll > 700) {
                this.sticky = true;
            } else {
                this.sticky = false;
            }
        },
        handleOpenDrawer(drawer) {
            this.$store.commit("app/setCurrentDrawerContent", drawer);
            this.$store.commit("app/setAppDrawer", !this.appDrawer);
        },
    },
};
</script>
