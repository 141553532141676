<template>
    <v-rating
        class="ps-rating"
        background-color="orange lighten-3"
        color="orange"
        length="5"
        readonly
        size="20"
        v-model="value"
    ></v-rating>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
    value: {
        type: Number,
        default: 0,
    },
});
const emits = defineEmits(["update:value"]);
const value = computed({
    set: (value) => emits("update:value", value),
    get: () => props.value,
});
</script>
