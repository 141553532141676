import Repository from "~/repositories/Repository.js";

export const storeReview = async (payload) => {
    try {
        const { data } = await Repository.post("/review/add", payload);
        return data;
    } catch (error) {
        console.log(error);
    }
};
export const getReviews = async (product_id) => {
    try {
        const { data } = await Repository.post("/review", {
            product_id
        });
        return data;
    } catch (error) {
        console.log(error);
    }
};
export const getProduct = async (payload) => {
    try {
        const { data } = await Repository.post("/product/get", {
            product_id: payload.slug,
            currency_cd: payload.currency_cd || "MYR",
        });
        return data;
    } catch (err) {}
};

export const getProducts = async () => {
    try {
        const { data } = await Repository.post("/footer");
        return data;
    } catch (err) {}
};
