import { serializeQuery } from "~/repositories/Repository.js";
import { baseUrl } from "~/repositories/Repository";
import { getHome, getFooter } from "~/api/home.js";

const state = () => ({
    recentViewed: [],
    data: null,
    footer: null,
    mainMenu: null,
});

const mutations = {
    SET_MAIN_MENU(state, payload) {
        state.mainMenu = payload;
    },
    SET_FOOTER(state, payload) {
        state.footer = payload;
    },
    SET_HOME(state, payload) {
        state.data = {
            ...payload,
            // Mapping main_banner for Banner component
            main_banner: payload.main_banner?.reduce((acc, cur) => {
                return [
                    ...acc,
                    cur.length
                        ? cur.map((item) => ({
                              img: item.image,
                              imgbg: "w-100",
                              link: item.link,
                          }))
                        : {
                              img: cur.image,
                              imgbg: "w-100",
                              link: cur.link,
                          },
                ];
            }, []),
            top_banner: payload.top_banner?.reduce((acc, cur) => {
                return [
                    ...acc,
                    cur.length
                        ? cur.map((item) => ({
                              img: item.image,
                              imgbg: "w-100",
                              link: item.link,
                          }))
                        : {
                              img: cur.image,
                              imgbg: "w-100",
                              link: cur.link,
                          },
                ];
            }, []),
            middle_banner: payload.middle_banner?.reduce((acc, cur) => {
                return [
                    ...acc,
                    cur.length
                        ? cur.map((item) => ({
                              img: item.image,
                              imgbg: "w-100",
                              link: item.link,
                          }))
                        : {
                              img: cur.image,
                              imgbg: "w-100",
                              link: cur.link,
                          },
                ];
            }, []),
        };
    },
    setRecentViewed(state, payload) {
        state.recentViewed = payload;
    },
};

const actions = {
    getHome: async ({ commit }) => {
        try {
            const data = await getHome();
            commit("SET_HOME", data.data);
        } catch (error) {}
    },
    getFooter: async ({ commit }) => {
        try {
            const data = await getFooter();
            commit("SET_FOOTER", data.data.footer);
        } catch (error) {}
    },
    async getProductCategories({ commit }, Name) {
        const payload = {
            name: Name,
        };
        const reponse = await Repository.get(
            `${baseUrl}/product-categories?${serializeQuery(payload)}`
        )
            .then((response) => {
                if (response.data && response.data.length) {
                    return response.data[0];
                } else {
                    return null;
                }
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    async getRecentViewed({ commit }) {
        const payload = {
            name: "Recent Viewed",
        };
        const reponse = await Repository.get(
            `${baseUrl}/product-categories?${serializeQuery(payload)}`
        )
            .then((response) => {
                if (response.data && response.data.length) {
                    commit("setRecentViewed", response.data[0].products);
                    return response.data[0].products;
                } else {
                    commit("setRecentViewed", []);
                    return null;
                }
            })
            .catch((error) => ({ error: JSON.stringify(error) }));
        return reponse;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
