import Repository from "~/repositories/Repository.js";
export const getHome = async () => {
    try {
        const { data } = await Repository.post("/home");
        return data;
    } catch (err) {}
};

export const getFooter = async () => {
    try {
        const { data } = await Repository.post("/footer");
        return data;
    } catch (err) {}
};
