<template>
    <div class="ps-product--mini-cart">
        <RouterLink
            class="ps-product__thumbnail"
            :to="{
                name: 'product.detail',
                params: {
                    slug: cart.product.short,
                },
            }"
            v-if="cart"
            ><img
                :src="cart.product.image"
                alt="alt"
                onerror="onerror=null; src='/img/placeholder.png'"
        /></RouterLink>
        <div class="ps-product__content">
            <RouterLink
                class="ps-product__name"
                :to="{
                    name: 'product.detail',
                    params: {
                        slug: cart.product.short,
                    },
                }"
                >{{ cart.product.name }}</RouterLink
            >
            <div class="ps-product__quantity">
                <div class="def-number-input number-input safari_only">
                    <button class="minus" @click="handleDecreaseItem()">
                        <i class="icon-minus"></i>
                    </button>
                    <input
                        class="quantity"
                        min="0"
                        :value="productQuantity"
                        type="number"
                        @input="(event) => handleChangeQuantity(event)"
                    />
                    <button class="plus" @click="handleincreaseItem()">
                        <i class="icon-plus"></i>
                    </button>
                </div>
            </div>
            <div class="ps-product__meta">
                <span :class="['ps-product__price', cart.product.promo_price ? 'promo' : '']"
                    >${{
                        cart.product.promo_price
                            ? cart.product.promo_price
                            : cart.product.sell_price
                    }}</span
                >
                <span class="ps-product__del" v-if="cart.product.sale_price"
                    >${{ cart.product.price }}</span
                >
            </div>
        </div>
        <a class="ps-product__remove" href="#" @click.prevent="removeCartItem()"
            ><i class="icon-cross"></i
        ></a>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
    cart: {
        type: Object,
    },
});
const store = useStore();
const cart = computed(() => props.cart);
const cartItems = computed(() => store.state.cart.cartItems);
const productQuantity = computed(() => {
    const item = cartItems.value.find((item) => item.id === cart.value.id);
    if (item) {
        return item.qty;
    }
    return 1;
});
const removeCartItem = () => {
    store.dispatch("cart/removeProductCart", cart.value.id);
    store.dispatch("cart/updateCart", { showToast: false });
};
const handleDecreaseItem = () => {
    const params = {
        qty: productQuantity.value - 1,
        id: cart.value.id,
    };
    _.debounce(() => {
        store.dispatch("cart/changeQuantityProductCart", params);
        store.dispatch("cart/updateCart", { showToast: false });
    }, 500);
};
const handleincreaseItem = () => {
    const params = {
        qty: productQuantity.value + 1,
        id: cart.value.id,
    };
    _.debounce(() => {
        store.dispatch("cart/changeQuantityProductCart", params);
        store.dispatch("cart/updateCart", { showToast: false });
    }, 500);
};
const handleChangeQuantity = (e) => {
    if (e.target.value) {
        const value = parseInt(e.target.value);
        let params = {
            qty: value < 0 ? 0 : value,
            id: cart.value.id,
        };
        store.dispatch("cart/changeQuantityProductCart", params);
        store.dispatch("cart/updateCart", { showToast: false });
    }
};
</script>
