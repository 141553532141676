<template>
    <div class="ps-login--modal">
        <form @submit.prevent="handleLogin()">
            <div class="form-group">
                <label>Email Address</label>
                <input class="form-control" type="text" v-model="form.email" />
            </div>
            <div class="form-group">
                <label>Password</label>
                <input class="form-control" type="password" v-model="form.password" />
            </div>
            <div class="form-group d-flex justify-space-between">
                <div class="form-check">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="form.remember"
                        id="remember-modal"
                        checked
                    />
                    <label class="form-check-label" for="remember-modal">Remember Me</label>
                </div>
            </div>
            <button
                :disabled="form.loading"
                type="submit"
                class="ps-btn ps-btn--warning d-flex justify-center align-items-center"
            >
                <v-progress-circular
                    v-if="form.loading"
                    indeterminate
                    size="20"
                    class="mr-2"
                ></v-progress-circular>
                <div>{{ form.loading ? "Processing ..." : "Log In" }}</div>
            </button>
            <RouterLink :to="{ name: 'forgot-password' }" class="mt-3 d-block"
                >Lost your password?</RouterLink
            >
            <div class="d-flex justify-center mt-4">
                <RouterLink :to="{ name: 'register' }" class="mt-3 d-block"
                    ><span class="text-red">Register Account</span></RouterLink
                >
            </div>
        </form>
    </div>
</template>

<script setup>
import { reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import Repository from "~/repositories/Repository";
import { toast } from "vue3-toastify";
import { useAuthStore } from "@ionsoft-digital/mystorely-portal";
import { computed } from "vue";

const authStore = useAuthStore();
const router = useRouter();

const form = reactive({
    email: "",
    password: "",
    remember: false,
    loading: computed(() => authStore.loading),
});

const handleLogin = async () => {
    const data = await authStore.login({
        email: form.email,
        password: form.password,
        remember: form.remember,
    });

    if (data.status_code == 200) {
        toast.success(data.description);
        // router.push({ name: "home" });
    } else {
        toast.error(data.message[0]);
    }
};
</script>
