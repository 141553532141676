<template>
    <ul :class="className">
        <li v-for="(item, index) in list" class="has-mega-menu" :key="index">
            <RouterLink :to="(categoryLink ? `/category/${item.short}` : `${item.short}`) || '#'" class="nav-link">
                <i v-if="index == 0 && !hiddenBar && item.megaContent" class="fa fa-bars"></i>
                {{ item.name }}
                <span class="has-badge" v-if="item.new">New</span>
            </RouterLink>
            <span class="sub-toggle" v-if="item.categories && item.megaContent">
                <i class="fa fa-chevron-down"></i>
            </span>
            <div class="mega-menu" v-if="item.megaContent">
                <div class="container">
                    <div class="mega-menu__row">
                        <div v-for="(val, indexVal) in item.categories" class="mega-menu__column">
                            <template v-if="val.name">
                                <h4>{{ val.name }}</h4>
                                <render-list :list="val.categories" className="sub-menu--mega" :categoryLink="true" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>
<script>
import { navPromo } from "~/static/data/menu.json";
import PromoItem from "~/components/elements/commons/PromoItem.vue";
import CountDown from "~/components/elements/commons/CountDown.vue";
import MenuMegaImage from "~/components/elements/menu/MenuMegaImage.vue";
import ProductStandard from "~/components/elements/product/ProductStandard.vue";
import MenuProduct from "~/components/elements/menu/MenuProduct.vue";

export default {
    name: "RenderList",
    props: {
        hiddenBar: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => [],
        },
        className: {
            type: String,
            default: "",
        },
        active: {
            type: Number,
            default: -1,
        },
        product: {
            type: Object,
            default: () => {},
        },
        categoryLink: {
            type: Boolean,
            default: false,
        },
    },
    components: { PromoItem, CountDown, MenuMegaImage, ProductStandard, MenuProduct },
    data() {
        return {
            promos: navPromo,
            active_el: null,
        };
    },
};
</script>
