export const localStorageKey = {
    wishlist: "demoretailspace-wishlist",
    coupon: "demoretailspace-coupon",
    user: "demoretailspace-user",
};
export const typesenseConfig = {
    apiKey: import.meta.env.VITE_SEARCH_API_KEY,
    productIndex: import.meta.env.VITE_SEARCH_PRODUCT_INDEX,
    nodes: [
        {
            host: import.meta.env.VITE_SEARCH_HOST,
            port: import.meta.env.VITE_SEARCH_PORT,
            protocol: import.meta.env.VITE_SEARCH_PROTOCOL,
        }
    ],
}
export const imageUrlBase = import.meta.env.VITE_IMAGE_URL_BASE;
