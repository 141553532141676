<template>
    <v-navigation-drawer v-model="drawer" fixed temporary style="width: 100%; height: 100vh !important">
        <navbar-slider v-if="currentDrawerContent === 'menu'" />
        <search-slider v-else-if="currentDrawerContent === 'search'" />
    </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import NavbarSlider from "~/components/shared/mobile/NavbarSlider.vue";
import SearchSlider from "~/components/shared/mobile/SearchSlider.vue";
export default {
    name: "MobileDrawer",
    components: { NavbarSlider, SearchSlider },
    computed: {
        ...mapState({
            appDrawer: (state) => state.app.appDrawer,
            currentDrawerContent: (state) => state.app.currentDrawerContent,
        }),
        drawer: {
            get() {
                return this.appDrawer;
            },
            set(newValue) {
                this.$store.commit("app/setAppDrawer", newValue);
                return newValue;
            },
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
