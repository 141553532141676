<template>
    <div class="ps-countdown">
        <div class="ps-countdown__content">
            <div class="ps-countdown__block ps-countdown__days">
                <div class="ps-countdown__number">
                    <span>{{ days.split("")[0] }}</span>
                    <span>{{ days.split("")[1] }}</span>
                </div>
                <div class="ps-countdown__ref">Days</div>
            </div>
            <div class="ps-countdown__block ps-countdown__hours">
                <div class="ps-countdown__number">
                    <span>{{ hours.split("")[0] }}</span>
                    <span>{{ hours.split("")[1] }}</span>
                </div>
                <div class="ps-countdown__ref">Hours</div>
            </div>
            <div class="ps-countdown__block ps-countdown__minutes">
                <div class="ps-countdown__number">
                    <span>{{ minutes.split("")[0] }}</span>
                    <span>{{ minutes.split("")[1] }}</span>
                </div>
                <div class="ps-countdown__ref">Mins</div>
            </div>
            <div class="ps-countdown__block ps-countdown__seconds">
                <div class="ps-countdown__number">
                    <span>{{ seconds.split("")[0] }}</span>
                    <span>{{ seconds.split("")[1] }}</span>
                </div>
                <div class="ps-countdown__ref">Secs</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "CountDown",
    data() {
        return {
            seconds: "00",
            minutes: "00",
            hours: "00",
            days: "00",
        };
    },
    props: {
        time: {
            type: String,
            default: "",
        },
    },
    mounted() {
        setInterval(() => {
            let deadline = moment.duration(moment(this.time).diff(moment(new Date())));
            this.days = deadline.days().toString();
            this.hours = deadline.hours().toString();
            this.minutes = deadline.minutes().toString();
            this.seconds = deadline.seconds().toString();
            if (deadline.days() < 10) {
                this.days = "0" + this.days;
            }
            if (deadline.hours() < 10) {
                this.hours = "0" + this.hours;
            }
            if (deadline.minutes() < 10) {
                this.minutes = "0" + this.minutes;
            }
            if (deadline.seconds() < 10) {
                this.seconds = "0" + this.seconds;
            }
        }, 1000);
    },
};
</script>

<style lang="scss" scoped></style>
