import App from "./App.vue";
import { createApp } from "vue";
import router from "./router";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import store from "./store";
import "./plugins/swiper-plugin";
import vuetify from "./plugins/vuetify";
import SlideUpDown from "vue3-slide-up-down";
import InstantSearch from "vue-instantsearch/vue3/es";
import "vue-skeletor/dist/vue-skeletor.css";
import _ from "lodash";
window._ = _;
import { createPinia } from "pinia";

import "@ionsoft-digital/mystorely-portal/dist/style.css";

const pinia = createPinia()

const app = createApp(App);

app.component("slide-up-down", SlideUpDown);
app.use(router);
app.use(store);
app.use(pinia)
app.use(InstantSearch);
app.use(vuetify);
app.use(Vue3Toastify, {
    autoClose: 2500,
    hideProgressBar: true,
    role: "default",
});

app.mount("#app");
