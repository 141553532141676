<template>
    <div class="ps-navigation--footer">
        <div class="ps-nav__item">
            <a
                href="javascript: void(0);"
                @click="handleOpenDrawer('menu')"
                v-if="!appDrawer || !currentDrawerContent || currentDrawerContent !== 'menu'"
            >
                <Icon icon="bx:menu" class="tw-text-3xl" />
            </a>
            <a
                href="javascript: void(0);"
                @click="handleCloseDrawer()"
                v-if="appDrawer && currentDrawerContent && currentDrawerContent === 'menu'"
                ><Icon icon="bx:x" class="tw-text-3xl"
            /></a>
        </div>
        <div class="ps-nav__item">
            <RouterLink to="/">
                <Icon icon="bx:home" class="tw-text-3xl" />
            </RouterLink>
        </div>
        <div class="ps-nav__item">
            <RouterLink to="/user/dashboard">
                <Icon icon="bx:user" class="tw-text-3xl" />
            </RouterLink>
        </div>
        <div class="ps-nav__item">
            <RouterLink to="/wishlist">
                <Icon icon="bx:heart" class="tw-text-3xl" />
                <span class="badge">{{ wishlistItems.length }}</span>
            </RouterLink>
        </div>
        <div class="ps-nav__item">
            <RouterLink to="/cart">
                <Icon icon="bx:cart" class="tw-text-3xl" />
                <span class="badge">{{ productCartQuantity }}</span>
            </RouterLink>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { Icon } from "@iconify/vue";

export default {
    name: "NavigationBottom",
    components: { Icon },
    computed: {
        ...mapState({
            appDrawer: (state) => state.app.appDrawer,
            currentDrawerContent: (state) => state.app.currentDrawerContent,
            cartItems: (state) => state.cart.cartItems,
            wishlistItems: (state) => state.wishlist.items,
        }),
        productCartQuantity() {
            let quantity = 0;
            this.cartItems.forEach((element) => {
                quantity += element.qty;
            });
            return quantity;
        },
    },
    data() {
        return {
            drawer: true,
        };
    },
    methods: {
        handleOpenDrawer(drawer) {
            this.$store.commit("app/setCurrentDrawerContent", drawer);
            this.$store.commit("app/setAppDrawer", !this.appDrawer);
        },
        handleCloseDrawer() {
            this.$store.commit("app/setCurrentDrawerContent", null);
            this.$store.commit("app/setAppDrawer", false);
        },
    },
};
</script>

<style lang="scss" scoped></style>
