<template>
    <v-app>
        <RouterView></RouterView>
    </v-app>
</template>

<script>

export default {
    created() {
        this.$store.commit('app/setLoading', true);
    }
};
</script>

<style lang="scss" scoped></style>
