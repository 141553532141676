import axios from "axios";

export const customHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
};

export const baseUrl = import.meta.env.VITE_API_URL;

export default axios.create({
    baseURL: baseUrl,
    headers: customHeaders,
});

export const serializeQuery = (query) => {
    return Object.keys(query)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
        .join("&");
};
