<template>
    <header :class="['ps-header ps-header--1', { 'ps-header--sticky': sticky }]">
        <notification />
        <div class="ps-header__top">
            <div class="container">
                <div class="ps-header__text">Need help? <strong>+60 19 313 1490</strong></div>
            </div>
        </div>
        <div class="ps-header__middle">
            <div class="container">
                <div class="ps-logo">
                    <RouterLink to="/">
                        <img src="/img/logo.jpg" alt />
                        <img class="sticky-logo" src="/img/sticky-logo.jpg" alt />
                    </RouterLink>
                </div>
                <a class="ps-menu--sticky" href="#" @click.prevent="handleOpenMenu()">
                    <Icon icon="bx:menu" />
                </a>
                <div class="ps-header__right">
                    <ul class="ps-header__icons">
                        <li class="">
                            <a
                                href="#"
                                class="active ps-header__item open-search"
                                @click.prevent="handleOpenDrawer('search')"
                            >
                                <i class="icon-magnifier"></i>
                            </a>
                        </li>
                        <li :class="{ 'ps-header__user': !authStore.isLoggedIn }">
                            <a
                                :href="authStore.isLoggedIn ? '/user/dashboard' : '#'"
                                class="active ps-header__item"
                            >
                                <Icon icon="bx:user" class="tw-text-3xl" />
                            </a>
                            <login-modal />
                        </li>
                        <li class="">
                            <RouterLink to="/wishlist" class="ps-header__item">
                                <Icon icon="bx:heart" class="tw-text-3xl" />
                                <span class="badge">
                                    {{ wishlistItems.length }}
                                </span>
                            </RouterLink>
                        </li>
                        <li class="ps-header__cart">
                            <a href="#" class="active ps-header__item">
                                <Icon icon="bx:cart" class="tw-text-3xl" />
                                <span class="badge">
                                    {{ productCartQuantity }}
                                </span>
                            </a>
                            <cart-mini />
                        </li>
                    </ul>
                    <!-- <language-currency /> -->
                    <form-search />
                </div>
            </div>
        </div>
        <div :class="['ps-navigation', { active: showNavigation }]">
            <div class="container">
                <div class="ps-navigation__left">
                    <nav class="ps-main-menu">
                        <render-list :list="mainMenu" className="menu" :product="product" />
                    </nav>
                </div>
                <div class="ps-navigation__right">Need help? <strong>+60 3 33 100 100</strong></div>
            </div>
        </div>
    </header>
</template>

<script>
import Notification from "~/components/shared/headers/modules/Notification.vue";
import { mainMenu } from "~/static/data/menu.json";
import RenderList from "~/components/elements/commons/RenderList.vue";
import LanguageCurrency from "~/components/shared/headers/modules/LanguageCurrency.vue";
import FormSearch from "~/components/shared/headers/modules/FormSearch.vue";
import LoginModal from "~/components/shared/headers/modules/LoginModal.vue";
import CartMini from "~/components/shared/headers/modules/CartMini.vue";
import { mapState } from "vuex";
import Repository from "~/repositories/Repository";
import { useAuthStore } from "@ionsoft-digital/mystorely-portal";
import { Icon } from "@iconify/vue";

export default {
    components: {
        Notification,
        RenderList,
        LanguageCurrency,
        FormSearch,
        LoginModal,
        CartMini,
        Icon,
    },
    data() {
        return {
            product: null,
            sticky: false,
            showNavigation: false,
            authStore: useAuthStore(),
        };
    },
    computed: {
        ...mapState({
            mainMenu: (state) => state.home.mainMenu,
            cartItems: (state) => state.cart.cartItems,
            wishlistItems: (state) => state.wishlist.items,
            appDrawer: (state) => state.app.appDrawer,
        }),
        productCartQuantity() {
            let quantity = 0;
            this.cartItems.forEach((element) => {
                quantity += element.qty;
            });
            return quantity;
        },
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll();
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    async mounted() {
        await this.getCategory();
    },
    methods: {
        async getCategory() {
            try {
                const { data } = await Repository.post("/header");
                this.$store.commit("home/SET_MAIN_MENU", [
                    {
                        name: "Shop Now",
                        short: "#",
                        megaContent: true,
                        categories: [...data.data.categoryGroup],
                    },
                    {
                        name: "Contact",
                        short: "/contact-us",
                    },
                ]);
            } catch (error) {}
        },
        handleScroll() {
            let scroll = window.scrollY;
            if (scroll > 200) {
                this.sticky = true;
            } else {
                this.sticky = false;
            }
        },
        handleOpenDrawer(drawer) {
            this.$store.commit("app/setCurrentDrawerContent", drawer);
            this.$store.commit("app/setAppDrawer", !this.appDrawer);
        },
        handleOpenMenu() {
            this.showNavigation = !this.showNavigation;
        },
    },
};
</script>
