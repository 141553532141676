import { localStorageKey } from "~/config/AppConfig";

const state = () => ({
    items: JSON.parse(localStorage.getItem(localStorageKey.wishlist)) || [],
});

const mutations = {
    SET_WISHLIST(state, payload) {
        state.items = payload;
    },
    setItems(state, payload) {
        const existItem = state.items.find((item) => item.id === payload.id);
        if (!existItem) {
            state.items.push({ ...payload });
        }
        localStorage.setItem(localStorageKey.wishlist, JSON.stringify(state.items));
    },
    removeItem(state, id) {
        const items = state.items;
        state.items = items.filter((el) => {
            return el.id != id;
        });
        localStorage.setItem(localStorageKey.wishlist, JSON.stringify(state.items));
    },
};
const actions = {
    addItemToWishlist({ commit, state }, payload) {
        commit("setItems", payload);
    },

    removeItemFromWishlist({ commit, state }, id) {
        commit("removeItem", id);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
