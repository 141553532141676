<template>
    <v-dialog v-model="dialog" max-width="1100" content-class="ps-dialog ps-quickview">
        <v-card class="ps-dialog__body">
            <button
                v-if="!dialogQuickviewLoading"
                class="ps-dialog__close"
                type="button"
                @click="closeModel(false)"
            >
                <i class="icon-cross"></i>
            </button>

            <div class="ps-quickview__body">
                <div class="ps-product--detail" v-if="!dialogQuickviewLoading">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <module-product-gallery :images="product.images" />
                        </div>
                        <div class="col-12 col-md-8">
                            <product-info />
                        </div>
                    </div>
                </div>
                <div class="d-flex" style="justify-content: center;" v-else>
                    <v-progress-circular indeterminate></v-progress-circular>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import ProductInfo from "~/components/elements/product/ProductInfo.vue";
import ModuleProductGallery from "~/components/elements/product/modules/ModuleProductGallery.vue";

export default {
    components: { ProductInfo, ModuleProductGallery },
    data() {
        return {
            design: {
                inStock: true,
                branch: "HeartRate",
                quickview: true,
                type: true,
            },
        };
    },
    computed: {
        ...mapState({
            dialogQuickview: (state) => state.cart.dialogQuickview,
            dialogQuickviewLoading: (state) => state.cart.dialogQuickviewLoading,
            product: (state) => state.product.product?.product || null,
        }),
        dialog: {
            set(value) {
                this.closeModel(value);
                return value;
            },
            get() {
                return this.dialogQuickview;
            },
        },
    },
    methods: {
        closeModel(value) {
            this.$store.commit("cart/setDialogQuickview", value);
        },
    },
};
</script>
