<template>
    <div class="ps-product ps-product--standard" v-if="product">
        <div class="ps-product__thumbnail">
            <RouterLink
                class="ps-product__image"
                :to="`/product/${product.short}`"
                v-if="product.images?.length"
            >
                <figure>
                    <img :src="product.image ? product.image : product.images[0].image" alt="alt" />
                    <!-- <img
                        v-if="product.thumbnail_back"
                        :src="product.images[0].image"
                        alt="alt"
                        onerror="onerror=null; src='/img/placeholder.png'"
                    /> -->
                </figure>
            </RouterLink>
            <div class="ps-product__actions">
                <div
                    class="ps-product__item"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Wishlist"
                >
                    <a href="#" @click.prevent="addProductToWishlist()">
                        <i class="fa fa-heart-o"></i>
                    </a>
                </div>
                <div
                    class="ps-product__item"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Quick view"
                >
                    <a href="#" @click.prevent="productQuickview()"><i class="fa fa-search"></i></a>
                </div>
                <div
                    class="ps-product__item"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Add to cart"
                >
                    <a href="#" @click.prevent="addToCart()">
                        <i class="fa fa-shopping-basket"></i>
                    </a>
                </div>
            </div>
            <div class="ps-product__badge" v-if="product.badges?.length">
                <div
                    v-for="item in product.badges"
                    :key="item.id"
                    :class="[
                        'ps-badge',
                        item.value == 'hot'
                            ? 'ps-badge--hot'
                            : item.value == 'new'
                            ? 'ps-badge--new'
                            : item.value == 'sale'
                            ? 'ps-badge--sale'
                            : 'ps-badge--sold',
                    ]"
                >
                    {{ item.name }}
                </div>
            </div>
            <div class="ps-product__percent" v-if="product.percent">
                {{ product.percent }}
            </div>
        </div>
        <div class="ps-product__content">
            <h5 class="ps-product__title">
                <RouterLink :to="`/product/${product.short}`">{{ product.name }}</RouterLink>
            </h5>
            <div class="ps-product__meta">
                <span :class="['ps-product__price', product.promo_price ? 'sale' : '']">
                    {{ product.currency_symbol_left }} {{ product.promo_price ? product.promo_price : product.sell_price }} {{ product.currency_symbol_right }}
                </span>
                <span class="ps-product__del" v-if="product.promo_price">
                    {{ product.currency_symbol_left }} {{ product.sell_price }} {{ product.currency_symbol_right }}
                </span>
            </div>
            <div class="ps-product__rating">
                <rating-component :value="product.rating" />
                <span class="ps-product__review">(Reviews)</span>
            </div>
            <div class="ps-product__actions ps-product__group-mobile">
                <div class="ps-product__quantity">
                    <div class="def-number-input number-input safari_only">
                        <button class="minus" @click="quantity > 0 ? quantity-- : quantity">
                            <i class="icon-minus"></i>
                        </button>
                        <input class="quantity" min="0" v-model="quantity" type="number" />
                        <button class="plus" @click="quantity++">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                </div>
                <div class="ps-product__cart">
                    <a class="ps-btn ps-btn--warning" href="#" @click.prevent="addToCart()">
                        <v-progress-circular
                            v-if="cartLoading"
                            indeterminate
                            size="20"
                            class="mr-2"
                        ></v-progress-circular>
                        <template v-else>
                            {{ cartLoading ? "Loading ..." : "Add to cart" }}
                        </template>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import RatingComponent from "~/components/elements/commons/RatingComponent.vue";
import { useStore } from "vuex";
import { ref, computed } from "vue";

const props = defineProps({
    product: {
        // temporary give the number and string
        type: [Object, Number, String],
    },
});
const store = useStore();
const product = computed(() => props.product);
const wishlist = computed(() => store.state.wishlist.items);

const productPrice = computed(() => {
    if (product.value) {
        return product.value.promo_price ? product.value.promo_price : product.value.sell_price;
    } else {
        return 0;
    }
});
const totalPrice = computed(() => Math.round(quantity * productPrice * 100) / 100);
const rating = computed(() => Math.floor(Math.random() * Math.floor(6) + 3));

const quantity = ref(1);
const cartLoading = ref(false);
const addToCart = async () => {
    const params = {
        id: product.value.id,
        product: product.value,
        variant_id: null,
        qty: quantity.value,
    };
    cartLoading.value = true;
    await store.dispatch("cart/addProductToCart", params);
    cartLoading.value = false;
};
const productQuickview = async () => {
    store.commit("cart/setDialogAddCart", false);
    store.commit("cart/setDialogQuickviewLoading", true);
    store.commit("cart/setDialogQuickview", true);
    await store.dispatch("product/getProduct", product.value.short);
    store.commit("cart/setDialogQuickviewLoading", false);
};
const isInWishlist = computed(() => {
    const item = wishlist.value.find((item) => item.id === product.value.id);
    return item ? true : false;
});
const addProductToWishlist = () => {
    if (isInWishlist.value) return;
    const params = {
        id: product.value.id,
        variant_id: null,
    };
    const title = product.value ? product.value.name : "";
    const message = {
        icon: "icon-shield-check",
        message: title + " has been added to your wishlist !",
    };
    store.dispatch("wishlist/addItemToWishlist", params);
    store.commit("app/setMessageNotify", message);
    store.commit("app/setDialogNotify", true);
};
</script>
