import { createStore } from "vuex";
import home from "./modules/home";
import wishlist from "./modules/wishlist";
import cart from "./modules/cart";
import app from "./modules/app";
import compare from "./modules/compare";
import auth from "./modules/auth";
import search from "./modules/search";
import product from "./modules/product";
import page from "./modules/page";

const store = createStore({
    modules: {
        home,
        product,
        wishlist,
        cart,
        app,
        compare,
        auth,
        search,
        page,
    },
});

export default store;
