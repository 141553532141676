<template>
    <div class="ps-product__info" v-if="product">
        <div class="ps-product__badge">
            <span class="ps-badge ps-badge--instock" v-if="product.inventory.in_stock">
                {{ product.inventory.qty_available && product.inventory.qty_available }} IN
                STOCK</span
            >
            <span class="ps-badge ps-badge--outstock" v-if="product.outStock"> OUT OF STOCK</span>
            <span class="ps-badge ps-badge--leftstock" v-if="product.leftStock">
                Only {{ design.available && design.available }} left in stock</span
            >
        </div>
        <div class="ps-product__title">
            <RouterLink to="/">{{ product.name }}</RouterLink>
        </div>
        <div class="ps-product__rating">
            <rating-component :value="product.rating" />
            <span class="ps-product__review">(5 Reviews)</span>
        </div>
        <div class="ps-product__meta">
            <div>
                <span :class="['ps-product__price', product.promo_price ? 'sale' : '']"
                    >{{ product.currency_symbol_left }} {{ product.promo_price ? product.promo_price : product.sell_price }} {{ product.currency_symbol_right }}</span
                >
                <span class="ps-product__del" v-if="product.promo_price"
                    >{{ product.currency_symbol_left }} {{
                        productVariant
                            ? productVariant.sell_price.toFixed(productVariant.currency_decimal)
                            : product.sell_price
                    }} {{ product.currency_symbol_right }}</span
                >
            </div>
        </div>
        <v-form ref="formEl">
            <div class="d-flex align-items-center">
                <template
                    v-if="productOptions.length > 0"
                    v-for="(option, index) in productOptions"
                    :key="option.name"
                >
                    <div class="mr-3 option-width">
                        <v-select
                            :items="option.options"
                            v-model="selectedVariantOption[index]"
                            variant="solo"
                            hide-details
                            return-object
                            item-title="value"
                            item-value="value"
                            clearable
                            :label="`${option.name}`"
                            @update:model-value="handleChangeOption()"
                            :rules="[(v) => !!v || 'Please select ' + option.name]"
                        ></v-select>
                    </div>
                </template>
                <a
                    href="#"
                    class="clear"
                    @click.prevent="clearSelection()"
                    v-if="showClear && selectedVariantOption.some((item) => item)"
                >
                    clear
                </a>
            </div>
            <div class="mt-2" v-if="errorMessages.length" style="font-size: 1rem; color: red">
                {{ errorMessages[0] }}
            </div>
            <div class="mt-2" v-if="showPrice && selectedVariantOption.some((item) => item)">
                <span
                    style="font-size: 1rem; color: red"
                    v-if="productVariantNotAvailable || !productVariant"
                >
                    Sorry, the product variant is out of stock
                </span>
            </div>
            <div class="ps-product__quantity mt-3">
                <h6>Quantity</h6>
                <div class="d-lg-flex align-items-center w-full justify-space-between">
                    <div class="d-lg-flex align-items-center" style="width: 50%">
                        <div class="def-number-input number-input safari_only mb-lg-0 mb-4 mr-3">
                            <button class="minus" @click="quantity > 0 ? quantity-- : quantity">
                                <i class="icon-minus"></i>
                            </button>
                            <input class="quantity" min="0" v-model="quantity" type="number" />
                            <button class="plus" @click="quantity++">
                                <i class="icon-plus"></i>
                            </button>
                        </div>
                        <button
                            class="ps-btn ps-btn--warning ml-0 mt-0"
                            :disabled="
                                addToCartLoading ||
                                (hasVariant
                                    ? showPrice
                                        ? productVariantNotAvailable
                                        : false
                                    : false)
                            "
                            @click.prevent="addToCart()"
                        >
                            <v-progress-circular
                                v-if="addToCartLoading"
                                indeterminate
                                size="20"
                                class="mr-2"
                            ></v-progress-circular>
                            <template v-else>Add to cart</template>
                        </button>
                    </div>
                    <RouterLink
                        class="ps-product__link"
                        :to="isInWishlist ? { name: 'shop.wishlist' } : '#'"
                        @click.prevent="addProductToWishlist()"
                    >
                        {{ isInWishlist ? "Go to wishlist" : "Add to Wishlist" }}
                    </RouterLink>
                </div>
            </div>
        </v-form>
        <div class="ps-product__type">
            <ul class="ps-product__list">
                <li>
                    <span class="ps-list__title">Tags: </span>
                    <template v-if="product.tags.length">
                        <RouterLink
                            to="#"
                            v-for="tag in product.tags"
                            :key="tag"
                            class="ps-list__text"
                            >{{ tag }}</RouterLink
                        >
                    </template>
                    <span v-else class="ps-list__text">-</span>
                </li>
                <li>
                    <span class="ps-list__title">SKU: </span
                    ><span class="ps-list__text">{{ product.sku || "-" }}</span>
                </li>
            </ul>
        </div>
        <div class="ps-product__social">
            <social-icon-color :link="product.link" />
        </div>
    </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import RatingComponent from "~/components/elements/commons/RatingComponent.vue";
import SocialIconColor from "~/components/shared/SocialIconColor.vue";
import { toast } from "vue3-toastify";

const props = defineProps({
    design: {
        type: Object,
        default: () => {},
    },
});
const store = useStore();
const product = computed(() => store.state.product.product?.product || null);
const productVariants = computed(() => store.state.product.product?.product_variants || null);
const hasVariant = computed(() => (productVariants.value?.length ? true : false));
const productOptions = computed(() => store.state.product.product?.product_options || null);
const formEl = ref();
const errorMessages = ref([]);

watch(product, (val) => {
    productVariant.value = null;
    productVariantNotAvailable.value = true;
    showPrice.value = false;
    showClear.value = false;
    errorMessages.value = [];
    selectedVariantOption.value = [];
});
const quantity = ref(1);
const selectedVariantOption = ref([]);
const productVariant = ref(null);
const productVariantNotAvailable = ref(true);
const showPrice = ref(false);
const showClear = ref(false);
const handleChangeOption = () => {
    const variant = productVariants.value.find((variant, i) => {
        const options = [];
        selectedVariantOption.value.forEach((option) => {
            if (option && option.value) {
                options.push(option.value);
            }
        });
        return variant.name == options.join(" ");
    });
    showClear.value = true;
    if (variant) {
        showPrice.value = true;
        productVariantNotAvailable.value = variant.inventory?.qty_available < 1 ? true : false;
        product.value.qty_available = variant.inventory.qty_available;
        productVariant.value = {
            ...variant,
            sell_price: parseFloat(variant.sell_price),
        };
    } else {
        showPrice.value = false;
    }
    resetFormValidation();
};
const clearSelection = async () => {
    productOptions.value.forEach((option, index) => {
        selectedVariantOption.value[index] = null;
    });
    showPrice.value = false;
    showClear.value = false;
    productVariantNotAvailable.value = true;
    resetFormValidation();
};
const resetFormValidation = () => {
    formEl.value.resetValidation();
    errorMessages.value = [];
};

const wishlist = computed(() => store.state.wishlist.items);
const isInWishlist = computed(() => {
    const item = wishlist.value.find((item) => item.id === product.value.id);
    return item ? true : false;
});

const addProductToWishlist = () => {
    if (isInWishlist.value) return;
    const params = {
        id: product.value.id,
        variant_id: productVariant.value?.id || null,
    };
    const title = product.value ? product.value.name : "";
    const message = {
        icon: "icon-shield-check",
        message: title + " has been added to your wishlist !",
    };
    store.dispatch("wishlist/addItemToWishlist", params);
    store.commit("app/setMessageNotify", message);
    store.commit("app/setDialogNotify", true);
};

const addToCartLoading = ref(false);
const addToCart = async () => {
    const { valid } = await formEl.value.validate();
    if (!valid) return (errorMessages.value = formEl.value.errors[0].errorMessages);
    const params = {
        id: product.value.id,
        product: product.value,
        variant_id: productVariant.value?.id || null,
        qty: quantity.value,
    };
    if (productVariant.value && productVariant.value.inventory.qty_available < 1) {
        return toast.error("Product variant is not available.");
    }
    addToCartLoading.value = true;
    await store.dispatch("cart/addProductToCart", params);
    addToCartLoading.value = false;
};
</script>
<style scoped>
:deep(.v-field--variant-solo) {
    background-color: #f0f2f5;
    box-shadow: none;
    border-radius: 999px;
}
.option-width {
    width: 30%;
}
.clear {
    display: block;
}
@media (min-width: 768px) {
    .option-width {
        width: 25%;
    }
}
</style>
