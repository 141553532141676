<template>
    <div class="ps-product--gallery">
        <div class="ps-swiper">
            <Swiper
                :loop="true"
                :loopedSlides="5"
                :centeredSlides="true"
                :modules="[Controller]"
                :controller="{ control: controlledSwiperTop }"
                @swiper="setControlledSwiperThumb"
                class="swiper ps-product__thumbnail"
            >
                <!-- <div class="swiper-wrapper" v-if="loading">
                    <div class="swiper-slide">
                        <v-skeleton-loader type="image"></v-skeleton-loader>
                    </div>
                </div> -->
                <SwiperSlide v-for="item in images" :key="item.index">
                    <img
                        :src="item.image"
                        width="100%"
                        alt
                        onerror="this.onerror=null; this.src='/img/placeholder.png'"
                    />
                </SwiperSlide>
            </Swiper>
        </div>
        <div class="ps-swiper">
            <Swiper
                :loop="true"
                :loopedSlides="5"
                :spaceBetween="15"
                :centeredSlides="true"
                :touchRatio="0.2"
                :slideToClickedSlide="true"
                slidesPerView="auto"
                :modules="[Controller]"
                :controller="{ control: controlledSwiperThumb }"
                @swiper="setControlledSwiperTop"
                class="swiper ps-gallery--image"
            >
                <!-- <div class="swiper-wrapper" v-if="loading">
                    <SwiperSlide v-for="item in 5" :key="item">
                        <v-skeleton-loader type="image"></v-skeleton-loader>
                    </SwiperSlide>
                </div> -->
                <SwiperSlide v-for="item in images" :key="item.index">
                    <div class="ps-gallery__item">
                        <img
                            :src="item.image"
                            alt
                            onerror="this.onerror=null; this.src='/img/placeholder.png'"
                        />
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Controller } from "swiper";
import { nextTick, onMounted, ref } from "vue";

defineProps({
    images: Array,
});
const controlledSwiperTop = ref(null);
const controlledSwiperThumb = ref(null);
// onMounted(async () => {
//     await nextTick();
//     setControlledSwiperTop();
// });

const setControlledSwiperThumb = (swiper) => {
    controlledSwiperThumb.value = swiper;
};
const setControlledSwiperTop = (swiper) => {
    controlledSwiperTop.value = swiper;
};
</script>
