<template>
    <Component :is="resolveLayout">
        <RouterView></RouterView>
    </Component>
</template>
<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import LayoutDefault from "./layouts/Default.vue";
import LayoutEmpty from "./layouts/Empty.vue";
const route = useRoute();
const resolveLayout = computed(() => {
    if (route.meta.layout == "empty") {
        return LayoutEmpty;
    }
    return LayoutDefault;
});
</script>
