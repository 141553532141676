<template>
    <footer class="ps-footer ps-footer--1">
        <div class="container">
            <!-- <footer-top /> -->
            <div class="ps-footer__middle">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4">
                                <FooterAddress />
                            </div>
                            <div class="col-12 col-sm-6 col-md-8">
                                <FooterContact />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-5">
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                <div class="ps-footer--block">
                                    <h5 class="ps-block__title">{{ informationData.name }}</h5>
                                    <RenderList
                                        :list="informationData.categories"
                                        :className="'ps-block__list'"
                                    />
                                </div>
                            </div>
                            <div class="col-6 col-sm-6">
                                <div class="ps-footer--block">
                                    <h5 class="ps-block__title">{{ accountData.name }}</h5>
                                    <RenderList
                                        :list="accountData.categories"
                                        :className="'ps-block__list'"
                                        :hiddenBar="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom :data="footer" />
        </div>
    </footer>
</template>

<script setup>
import FooterBottom from "~/components/shared/footers/modules/FooterBottom.vue";
// import FooterTop from "~/components/shared/footers/modules/FooterTop.vue";
import FooterContact from "~/components/shared/footers/modules/FooterContact.vue";
import FooterAddress from "~/components/shared/footers/modules/FooterAddress.vue";
import {
    information as informationData,
    account as accountData,
    store as storeData,
} from "~/static/data/footer.json";
import RenderList from "~/components/elements/commons/RenderList.vue";
import { onMounted, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const footer = computed(() => store.state.home.footer);

onMounted(async () => {
    await store.dispatch("home/getFooter");
});
</script>
