import { toast } from "vue3-toastify";
import { localStorageKey } from "~/config/AppConfig";
import Repository from "~/repositories/Repository";
import router from "~/router";

const state = () => ({
    isLoggedIn: false,
    user: JSON.parse(localStorage.getItem(localStorageKey.user)) || null,
});
// getters
const getters = {
    user: (state) => state.user,
    isLoggedIn: (state) => !!state.user,
    shippingAddress: (state) => state.address.filter((address) => address.type == "S"),
};
const mutations = {
    SET_USER_LOGIN(state, data) {
        state.user = data;
        localStorage.setItem(localStorageKey.user, JSON.stringify(data));
    },
    CLEAR_USER_LOGIN(state, data) {
        state.user = null;
        localStorage.removeItem(localStorageKey.user);
    },
};

const actions = {
    async getUserProfile({ commit }) {
        try {
            const { data } = await Repository.post("/user/profile/get", {}, { withCredentials: true });
            commit("SET_USER_LOGIN", data.data);
            return data.data;
        } catch (error) {
            console.log(error);
        }
    },
    checkUserLoggedIn({ commit, state }) {
        return !!state.user ? commit("SET_USER_LOGIN", state.user) : commit("CLEAR_USER_LOGIN");
    },
    async logout({ commit }) {
        try {
            const response = await Repository.post("/logout", {}, { withCredentials: true });
            toast.success(response.data.description);
            router.replace({ name: "Login" });
            setTimeout(() => {
                commit("CLEAR_USER_LOGIN");
            }, 500);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
