<template>
    <v-app>
        <v-main class="ps-page">
            <HeaderV1 />
            <HeaderMobile />
            <RouterView :style="{ 'padding-top: 290px': sticky }"></RouterView>
            <FooterV1 />
        </v-main>
        <NavigationBottom />
        <MobileDrawer />
        <ScrollTop />
        <LanguageDialog />
        <CurrencyDialog />
        <QuickView />
        <!-- <CompareDialog /> -->
        <add-product-v1 />
        <NotifyDialog />
        <Loading />
    </v-app>
</template>

<script>
import NavigationBottom from "~/components/shared/mobile/NavigationBottom.vue";
import MobileDrawer from "~/components/shared/mobile/MobileDrawer.vue";
import QuickView from "~/components/elements/commons/QuickView.vue";
import ScrollTop from "~/components/shared/ScrollTop.vue";
import LanguageDialog from "~/components/elements/commons/LanguageDialog.vue";
import CurrencyDialog from "~/components/elements/commons/CurrencyDialog.vue";
import CompareDialog from "~/components/elements/commons/CompareDialog.vue";
import AddProductV1 from "~/components/elements/commons/AddProductV1.vue";
import NotifyDialog from "~/components/elements/commons/NotifyDialog.vue";
import FooterV1 from "~/components/shared/footers/FooterV1.vue";
import HeaderV1 from "~/components/shared/headers/HeaderV1.vue";
import HeaderMobile from "~/components/shared/mobile/headers/HeaderV1.vue";
import Loading from "~/components/shared/Loading.vue";

export default {
    components: {
        NavigationBottom,
        MobileDrawer,
        QuickView,
        ScrollTop,
        LanguageDialog,
        CurrencyDialog,
        CompareDialog,
        AddProductV1,
        NotifyDialog,
        FooterV1,
        HeaderV1,
        HeaderMobile,
        Loading,
    },
    created() {
        this.$store.commit("app/setLoading", true);
        this.$store.commit("cart/setDialogAddCart", false);
        this.$store.commit("cart/setDialogQuickview", false);
        this.$store.commit("app/setCurrentDrawerContent", null);
        this.$store.commit("app/setAppDrawer", false);
        this.$store.commit("cart/setDialogAddCart", false);
    },
    data() {
        return {
            sticky: false,
        };
    },
    methods: {
        handleScroll() {
            let scroll = window.scrollY;
            if (scroll > 200) {
                this.sticky = true;
            } else {
                this.sticky = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
