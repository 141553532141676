const transformCartToBackendRequest = (data) => {
    const carts = [];
    data.forEach((cart) => {
        carts.push({
            product_id: cart.id,
            product_variant_id: cart.variant_id || null,
            qty: cart.qty,
        });
    });
    return JSON.stringify(carts);
};
const transformWishlistToBackendRequest = ({ state }) => {
    const wishlists = [];
    state.wishlist.forEach((wishlist) => {
        wishlists.push({
            product_id: wishlist.id,
            product_variant_id: wishlist.variantId,
            qty: wishlist.quantity,
        });
    });
    return JSON.stringify(wishlists);
};
const transformCartAndWishlist = (payload) => {
    const result = [];
    payload.forEach((data) => {
        result.push({
            id: data.product_id,
            product: {
                ...data,
                name: data.name,
                short: data.short || "test",
                sell_price: parseFloat(data.price),
                image: data.image,
            },
            variant_id: data.product_variant_id,
            qty: data.qty || 1,
        });
    });
    return result;
};

export {
    transformCartToBackendRequest,
    transformWishlistToBackendRequest,
    transformCartAndWishlist,
};
