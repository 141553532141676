<template>
    <div class="ps-footer--address">
        <div class="ps-logo">
            <RouterLink to="/">
                <img src="/img/sticky-logo.jpg" alt />
                <img class="logo-white" src="/img/logo-white.png" alt />
                <img class="logo-black" src="/img/Logo-black.png" alt />
                <img class="logo-white-all" src="/img/logo-white1.png" alt />
                <img class="logo-green" src="/img/logo-green.png" alt />
            </RouterLink>
        </div>
        <div class="ps-footer__title">Our store</div>
        <p>Suite 9-03, 9th Floor, The Ampwalk<br />North Block, 218, Jalan Ampang<br />50450 Kuala Lumpur</p>
        <p>
            <a target="_blank"
                href="https://maps.app.goo.gl/rFcaNQEVGmkHS2av9">Show on map</a>
        </p>
        <social-icon />
    </div>
</template>

<script>
import SocialIcon from '~/components/shared/SocialIcon.vue';
export default {
    components: { SocialIcon }
};
</script>
