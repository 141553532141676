<template>
    <div class="ps-noti" v-show="show">
        <div class="container">
            <p class="m-0">
                This is your upcoming Retail Store Space. Go global. Go e-commerce.
            </p>
        </div>
        <a class="ps-noti__close" href="#" @click.prevent="show = false"><i class="icon-cross"></i></a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true
        };
    }
};
</script>
