const state = () => ({
    activeTab: { MyDashboard: true },
});

const mutations = {
    setTab(state, payload) {
        state.activeTab = payload;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
