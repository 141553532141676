<template>
    <ul class="ps-social">
        <li v-for="social in socials" :key="social.type">
            <a :class="`ps-social__link ${social.type}`" href="#">
                <i :class="`fa fa-${getSocialIcon(social.type)}`"> </i>
                <span class="ps-tooltip" style="text-transform: capitalize;">{{ social.type }}</span>
            </a>
        </li>
    </ul>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const socials = computed(() => store.state.home.footer?.social);
const getSocialIcon = (type) => {
    switch (type) {
        case "youtube":
            return "youtube-play"
        case "pinterest":
            return "pinterest-p"
        default:
            return type;
    }
}
</script>
