<template>
    <div class="ps-cart--mini">
        <div class="ps-cart__empty" v-if="!cartItems.length">
            <div class="ps-cart__icon">
                <i class="fa fa-shopping-basket"></i>
            </div>
            <p class="ps-cart__text">Your cart is currently empty</p>
        </div>
        <template v-else>
            <ul class="ps-cart__items">
                <li class="ps-cart__item" v-for="cart in cartItems" :key="cart.id">
                    <product-mini-cart :cart="cart" />
                </li>
            </ul>
            <div class="ps-cart__total">
                <span>Subtotal </span><span>${{ totalCart }}</span>
            </div>
            <div class="ps-cart__footer">
                <RouterLink class="ps-btn ps-btn--outline" :to="{ name: 'shop.cart' }"
                    >View Cart</RouterLink
                ><RouterLink class="ps-btn ps-btn--warning" :to="{ name: 'shop.checkout' }"
                    >Checkout</RouterLink
                >
            </div>
        </template>
    </div>
</template>
<script setup>
import { useStore } from "vuex";
import ProductMiniCart from "~/components/elements/product/ProductMiniCart.vue";
import { computed, onMounted } from "vue";

const store = useStore();
const cartItems = computed(() => store.state.cart.cartItems);
const totalCart = computed(() => {
    let total = 0;
    cartItems.value.forEach((cart) => {
        let price = productPrice(cart);
        let quantity = productQuantity(cart);
        total += price * quantity;
    });
    return total.toFixed(2);
});

onMounted(async () => {
    await store.dispatch("cart/getCart");
});

const productPrice = (cart) => {
    if (cart.product) {
        return cart.product.promo_price ? cart.product.promo_price : cart.product.sell_price;
    } else {
        return 0;
    }
};
const productQuantity = (cart) => {
    const item = cartItems.value.find((item) => item.id === cart.id);
    if (item) {
        return item.qty;
    }
    return 1;
};
</script>
