<template>
    <div class="ps-preloader" id="preloader">
        <div class="ps-preloader-section ps-preloader-left"></div>
        <div class="ps-preloader-section ps-preloader-right"></div>
    </div>
</template>

<script>
export default {
    name: 'Loading'
};
</script>

<style lang="scss" scoped></style>
