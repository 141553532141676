<template>
    <ul :class="className">
        <li
            v-for="(item, index) in list"
            :class="{ active: active[index] == true }"
            class="menu-item-has-children"
            :key="index"
        >
            <RouterLink
                :to="`/category/${item.short ? item.short : '#'}`"
                class="nav-link"
                @click.native="openSubItem(index)"
            >
                {{ item.name }}
            </RouterLink>
            <span
                class="sub-toggle"
                v-if="item.categories && item.megaContent"
                @click="openSubItem(index)"
            >
                <i class="fa fa-chevron-down"></i>
            </span>
            <slide-up-down v-model="active[index]">
                <ul v-if="item.megaContent" class="sub-menu">
                    <li
                        v-for="(val, idx) in item.categories"
                        :key="idx"
                        :class="{ active: subActive[idx] == true }"
                    >
                        <RouterLink
                            :to="`/category/${item.short ? item.short : '#'}`"
                            class="nav-link"
                            @click.native="openSubChild(idx)"
                        >
                            {{ val.name }}
                        </RouterLink>
                        <span class="sub-toggle" v-if="val.categories" @click="openSubChild(idx)">
                            <i class="fa fa-chevron-down"></i>
                        </span>
                        <slide-up-down v-model="subActive[idx]">
                            <module-main-menu :list="val.categories" className="sub-menu" />
                        </slide-up-down>
                    </li>
                </ul>
            </slide-up-down>
        </li>
    </ul>
</template>

<script>
export default {
    name: "module-main-menu",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        className: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            active: [],
            subActive: [],
        };
    },
    methods: {
        openSubItem(index) {
            this.active[index] = !this.active[index];
            this.active.forEach((item, i) => {
                if (i !== index) this.active[i] = false;
            });
        },
        openSubChild(index) {
            this.subActive[index] = !this.subActive[index];
            this.subActive.forEach((item, i) => {
                if (i !== index) this.subActive[i] = false;
            });
        },
    },
};
</script>
