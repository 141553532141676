const blog = [
    {
        path: "/blog/masonry",
        name: "blog.masonry",
        component: () => import("~/views/blog/Masonry.vue"),
    },
    {
        path: "/blog/sidebar",
        name: "blog.sidebar",
        component: () => import("~/views/blog/Sidebar.vue"),
    },
    {
        path: "/blog/sidebar-2",
        name: "blog.sidebar-2",
        component: () => import("~/views/blog/Sidebar2.vue"),
    },
    {
        path: "/blog/detail/:id",
        name: "blog.detail",
        component: () => import("~/views/blog/detail/Index.vue"),
    },
    {
        path: "/blog/detail-2/:id",
        name: "blog.detail",
        component: () => import("~/views/blog/detail/Index.vue"),
    },
];

export default blog;
