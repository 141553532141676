const shop = [
    {
        path: "/shop",
        name: "shop",
        component: () => import("~/views/shop/List.vue"),
    },
    {
        path: "/category/:short?",
        name: "shop.list",
        component: () => import("~/views/shop/List.vue"),
    },
    {
        path: "/search/:searchText?",
        name: "shop.productList",
        component: () => import("~/views/shop/List.vue"),
    },
    {
        path: "/wishlist",
        name: "shop.wishlist",
        component: () => import("~/views/shop/Wishlist.vue"),
    },
    {
        path: "/cart",
        name: "shop.cart",
        component: () => import("~/views/shop/Cart.vue"),
    },
    {
        path: "/shop/checkout",
        name: "shop.checkout",
        component: () => import("~/views/shop/Checkout.vue"),
    },
    {
        path: "/order/pending/:orderNumber",
        name: "order.pending",
        component: () => import("~/views/shop/OrderPending.vue"),
    },
    {
        path: "/order/complete/:orderNumber",
        name: "order.complete",
        component: () => import("~/views/shop/OrderComplete.vue"),
    },
    {
        path: "/order/fail/:orderNumber",
        name: "order.fail",
        component: () => import("~/views/shop/OrderFail.vue"),
    },
    {
        path: "/order/details/:orderNumber",
        name: "order.details",
        component: () => import("~/views/shop/OrderDetails.vue"),
    },
];

export default shop;
